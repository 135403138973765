<template>
    <div class="wrap" style="height: 100%">
        <div class="main_title">Recommended</div>
        <div class="flex_layout inner">
            <div class="flex_top">
                <div class="query flex">
                    <a-space>
                        <div class="query_box">
                            <a-input v-model:value="formData.recommended_name" placeholder="Recommended name" />
                        </div>
                        <div class="query_box">
                            <a-space>
                                <a-button @click="getRecommendedListHandler">
                                   <template #icon><component :is="$antIcons['SearchOutlined']" /></template>
                                </a-button>
                                <a-button class="primary btn" @click="update('add')">Add</a-button>
                            </a-space>
                        </div>
                    </a-space>
                </div>
            </div>
            <div class="flex_body">
                <a-spin :spinning="spinning">
                   <a-table :dataSource="dataSource" :columns="columns">
                        <template #action="{record}">
                            <a-space>
                                <a-button class="primary btn" @click="update('edit', record)" size="small" type="primary">Edit</a-button>
                                <a-popconfirm
                                    title="Are you sure delete this?"
                                    ok-text="Yes"
                                    cancel-text="No"
                                    @confirm="() => remove(record.recommended_id)"
                                >
                                    <a-button danger size="small">Delete</a-button>
                                </a-popconfirm>
                            </a-space>
                        </template>
                        <template #range="{record}">
                         <span>{{record.min}} - {{record.max}}</span>
                        </template>
                        <template #classify="{record}">
                         <span>{{getClassify(record.classify)}}</span>
                        </template>
                        <template #image="{record}">
                          <img :src="record.image" class="product_image" style="width: 60px">
                        </template>
                   </a-table>
                </a-spin>
            </div>
            <div class="flex_bottom">
                <a-pagination size="small" v-model:current="query.page" :total="total" show-less-items />
            </div>
        </div>
        <!--推荐-->
        <a-drawer
            title="Recommended"
            placement="bottom"
            height="100%"
            :visible="visible"
            @close="onClose"
            :drawerStyle="{background: '#f9f9f9'}"
        >
            <div class="container">
                <a-spin :spinning="spinning">
                    <div class="form" style="width: 100%">
                        <div class="form_title">Recommended</div>
                        <a-form :model="formData" :label-col="labelCol" :wrapper-col="wrapperCol">
                            <a-form-item label="Recommended name">
                                <a-input v-model:value="formData.recommended_name" placeholder="Recommended name" />
                            </a-form-item>  
                            <a-form-item label="Recommended feature">
                                <a-textarea :rows="3" v-model:value="formData.recommended_feature" placeholder="Recommended feature" />
                            </a-form-item>   
                            <a-form-item label="Recommended price">
                                <a-input-number style="width:200px" v-model:value="formData.recommended_price" placeholder="Recommended price" />
                            </a-form-item>   
                            <a-form-item label="Save ratio">
                                <a-input-number style="width:200px" v-model:value="formData.save_ratio" placeholder="Save ratio" />
                            </a-form-item> 
                            <a-form-item label="Recommended image">
                                <img :src="recommended_image" class="recommended_image">
                                <a-upload :customRequest="(file) => customRequest(file.file)" :showUploadList="false">
                                    <a-button>
                                        <component :is="$antIcons['UploadOutlined']" />
                                        Upload
                                    </a-button>
                                </a-upload>
                            </a-form-item>   
                            <a-form-item label="Recommended description">
                                <Editor @onContent="onContent" :content="formData.recommended_description" />
                            </a-form-item>  
                            <a-form-item label="Bill range">
                                <a-space>
                                    <a-input-number style="width:200px" v-model:value="formData.min" placeholder="Min"/>
                                    <a-input-number style="width:200px" v-model:value="formData.max" placeholder="Max"/>
                                </a-space>
                            </a-form-item>  
                            <a-form-item label="Region">
                                 <a-select
                                    ref="select"
                                    v-model:value="formData.region_id"
                                    :options="regionList"
                                    placeholder="Select Region"
                                    style="width: 200px"
                                >
                                </a-select>
                            </a-form-item> 
                            <a-form-item label="Interested">
                                <a-select
                                    ref="select"
                                    v-model:value="formData.classify"
                                    :options="interesteList"
                                    placeholder="Select interested"
                                    style="width: 200px"
                                >
                                </a-select>
                            </a-form-item>  
                            <a-form-item label="Products">
                                <a-button size="small" style="margin: 5px auto" @click="openProduct">Add</a-button>
                                <a-empty v-if="formData.products.length == 0" />
                                <div class="products" v-else>
                                    <div class="product_cell flex" v-for="(product, idx) in formData.products" :key="idx">
                                        <span>{{product.product_name}}</span>
                                        <a-button danger size="small" @click="removeProduct(idx)">Delete</a-button>
                                    </div>
                                </div>
                            </a-form-item>  
                            <a-form-item :wrapper-col="{ span: 19, offset: 5 }">
                                <a-button type="primary" @click="updateRecommendedHandler">Submit</a-button>
                            </a-form-item>
                        </a-form>
                    </div>
                </a-spin>
            </div>
        </a-drawer>
        <!--产品-->
        <a-drawer
            title="Add Product"
            placement="bottom"
            height="100%"
            :visible="productVisible"
            @close="onProductClose"
            :drawerStyle="{background: '#f9f9f9'}"
        >
            <div class="container">
                <a-spin :spinning="spinning">
                    <AddProduct @onProductClose="onProductClose" @onProduct="onProduct" :list="formData.products" />
                </a-spin>
            </div>
        </a-drawer>
    </div>
</template>
<script>
const columns = [
    {
        title: 'Recommended name',
        dataIndex: 'recommended_name',
        key: 'recommended_name',
    },
    {
        title: 'Recommended image',
        slots: { customRender: 'image' },
    },
    {
        title: 'Recommended price',
        dataIndex: 'recommended_price',
        key: 'recommended_price',
    },
    {
        title: 'Region',
        dataIndex: 'region_name',
        key: 'region_name',
    },
    {
        title: 'Range',
        slots: { customRender: 'range' },
    },
    {
        title: 'classify',
        slots: { customRender: 'classify' },
    },
    {
        title: 'Action',
        key: 'action',
        slots: { customRender: 'action' },
    },
]
import { upload } from '@/apis//system'
import Editor from '@/components/editor/editor'
import { interesteList } from '@/config/const'
import AddProduct from '@/components/product/addProduct.vue'
import { getRegionList } from '@/apis/region'
import { getRecommendedList, updateRecommended, removeRecommended } from '@/apis/recommended'
export default {
    name: 'Recommended',
    components: {
        Editor,
        AddProduct
    },
    data(){
        return {
            dataSource: [],
            columns,
            query: {
                product_name: '',
                page: 1,
                page_size: 10
            },
            formData: {},
            productList: [],
            total: 1,
            recommended_image: '',
            labelCol: { span: 5 },
            wrapperCol: { span: 19 },
            regionList: [],
            interesteList,
            spinning: false,
            visible: false,
            productVisible: false
        }
    },
    methods: {
        /**
         * 编辑更新推荐弹窗
         */
        update(actionType, productInfo){
            if(actionType == 'add'){
                this.initParams()
                this.recommended_image = ''
            }
            if(actionType == 'edit'){
                this.recommended_image = productInfo.image
                this.formData = productInfo
            }
            this.formData.action_type = actionType
            this.getRegionListHandler()
            this.visible = true
        },
        /**
         * 关闭弹窗
         */
        onClose(){
            this.visible = false
        },
        onProductClose(){
            this.productVisible = false
        },
        /**
         * 打开产品弹窗
         */
        openProduct(){
            this.productVisible = true
        },
        /**
         * 产品回调
         */
        onProduct(products){
            this.formData.products = products
        },
        /**
         * 添加产品
         */
        addProduct(product){
            this.formData.products.push(product)
        },
        /**
         * 删除产品
         */
        removeProduct(index){
            this.formData.products.splice(index ,1)
        },
        /**
         * 推荐产品
         */
        async remove(recommended_id){
            this.spinning = true
            const res = await removeRecommended({recommended_id})
            this.spinning = false
            if(res){
                this.getRecommendedListHandler()
            } 
        },
        /**
         * 推荐列表
         */
        async getRecommendedListHandler(){
            this.spinning = true
            const res = await getRecommendedList(this.query)
            this.spinning = false
            if(res){
                this.dataSource = res.list.rows
                this.total = res.list.count
            }
        },
        /**
         * 区域
         */
        async getRegionListHandler(){
            const res = await getRegionList()
            if(res){
                this.regionList = res.list.map(r => {
                    r.value = r.region_id
                    r.label = r.region_name
                    return r
                })
            }
        },
        /**
         * 更新推荐
         */
        async updateRecommendedHandler(){
            if(this.formData.recommended_name == '') return message.error('Please input recommended name')
            this.spinning = true
            const res = await updateRecommended(this.formData)
            this.spinning = false
            if(res){
                this.visible = false
                this.getRecommendedListHandler()
            } 
        },
        /**
         * 图片上传
         */
        async customRequest(file){
            this.spinning = true
            const res = await upload(file)
            this.spinning = false
            if(res){
                this.recommended_image = res.file_name
                this.formData.recommended_image = res.name
            }
        },
        /**
         * 编辑器回调
         */
        onContent(html){
            this.formData.recommended_description = html
        },
         /**
         * 初始化参数
         */
        initParams(){
            this.formData = {
                recommended_id: 0,
                recommended_name: '',
                recommended_feature: '',
                recommended_description: '',
                recommended_price: '',
                recommended_image: '',
                min: '',
                max: '',
                classify: 1,
                region_id: undefined,
                state: 0,
                save_ratio: 0,
                products: [],
                action_type: 'add'
            }
        }
    },
    computed: {
        getClassify(){
            return classify_id => {
                const arr = interesteList.filter(r => r.id == classify_id)
                return arr[0].name
            }
        }
    },
    created(){
        this.initParams()
        this.getRecommendedListHandler()
    }
}
</script>
<style lang="stylus" scoped>
.product_cell
    justify-content space-between
    border-bottom 1px solid #eee
    margin-top 10px
    padding-bottom 10px
.recommended_image
    width 80px
    display block
    margin-bottom 10px
</style>
import request from '@/request'


export const getRecommendedList = (data = {}) => {
  return request({
    url: '/admin/recommended/get_recommended_list',
    method: 'get',
    params: data
  })
}
export const updateRecommended = (data = {}) => {
return request({
  url: '/admin/recommended/update_recommended',
  method: 'post',
  data
})
}
export const removeRecommended = (data = {}) => {
return request({
  url: '/admin/recommended/remove_recommended',
  method: 'post',
  data
})
}